export const LOGIN = "/api/auth/send-otp/:param"
export const LOGOUT = "/auth/logout"
export const OTP = "/api/auth/login"
export const FORGOT_PASSWORD = "/api/v1/authentication/forget-password"
export const RESET_PASSWORD = "/api/v1/authentication/reset-password/:param"
export const FETCH_TOKEN = "/api/v1/authentication/check-token/:param"

export const REGISTER = "/api/auth/register"
export const CHECK_CITIZEN = "/api/citizen/inscription/check"
export const CHECK_BIO_DATA_CITIZEN = "/api/citizen/inscriptionBioData/check"
export const CHECK_FOREIGN_CITIZEN = "/api/citizen/inscriptionForeign/check"

export const INSCRIPTION = "/api/citizen/inscription"
export const INSCRIPTION_BIO = "/api/citizen/inscriptionBio"
export const INSCRIPTION_FOREIGN = "/api/citizen/inscriptionForeign"


export const INSCRIPTION_RESEND_OTP = "/api/citizen/inscription/resend/otp"
export const INSCRIPTION_FOREIGN_RESEND_OTP = "/api/citizen/inscriptionForeign/resend/otp"
export const INSCRIPTION_BIO_RESEND_OTP = "/api/citizen/inscriptionBio/resend/otp"
export const EVAX_NUMBER_RECUP = "/api/auth/recover_code_evax"


export const INSCRIPTION_OTP = "/api/citizen/inscription/otp"
export const INSCRIPTION_BIO_OTP = "/api/citizen/inscriptionBio/otp"
export const INSCRIPTION_FOREIGN_OTP = "/api/citizen/inscriptionForeign/otp"

export const CHECK_PHONE = "/api/citizen/phone/check"
export const CHANGE_PHONE = "/api/citizen/phone/change"
export const CHANGE_PHONE_OTP = "/api/citizen/phone/otp"

export const PROFILE_UPDATED= "/api/auth/profile"

export const CHANGE_PHONE_LOGGED_CITIZEN = "/api/citizen/logged/phone/change"
export const CHANGE_PHONE_LOGGED_CITIZEN_OTP = "/api/citizen/logged/phone/otp"
export const CHANGE_PHONE_LOGGED_CHECK_CITIZEN = "/api/citizen/logged/phone/check"

