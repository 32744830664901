import { CALL_API } from './../constants'
import { makeCall, objectToFormData } from './../helpers'
import { PURGE } from 'redux-persist'
import { CHECK_CONNECTIVITY, FORBIDDEN_ERROR } from './../components/connectivity'
import { ACTIONS } from 'src/modules/authentication/store/constants'
import { getLang } from 'src/i18n'

const api = store => next => async action => {
  if (!action || !action.type || action.type !== CALL_API ) {
    return next(action)
  }

  const { actions, endpoint, method, auth, params = {}, isFormData = false, returnResponse = false, extra = {}, headers = {} } = action.meta
  const { token } = store.getState().common.auth || {}

  let payload = action.payload

  const dispatch = (type, payload) => next({ type, payload })
  if (actions.init) {
    dispatch(actions.init, { "lang": getLang(), ...params },
  )
  }

  // check for authorization
  if (auth) {
    headers.Authorization = `Bearer ${token}`
  }

  // check for form data
  if (isFormData) {
    headers['Content-Type'] = 'multipart/form-data'
    payload = objectToFormData(action.payload)
  }

  // make the request
  makeCall(method, endpoint, payload, headers, params, extra)
    .then(resp => {
      if (returnResponse) {
        dispatch(actions.success, resp)
      } else {
        dispatch(actions.success, resp.data)
      }
    })
    .catch(err => {
      if (err.message === 'Network Error') {
        next({
          type: CHECK_CONNECTIVITY
        })
      }
      if (err.response && err.response?.status === 401 && auth) {
        next({
          type: PURGE,
          result: () => null
        })
      } 
      else if (err.response?.status === 403 ){
        next({type:FORBIDDEN_ERROR})
      }
      else {
        dispatch(actions.fail, err.response || {})
      }
    })
}

export default api
