// gateway
export const FETCH_GATEWAYS_INIT = "gateway@FETCH_GATEWAYS_INIT"
export const FETCH_GATEWAYS_SUCCEDED = "gateway@FETCH_GATEWAYS_SUCCEDED"
export const FETCH_GATEWAYS_FAILED = "gateway@FETCH_GATEWAYS_FAILED"

export const CLEAR_GATEWAY = "gateway@CLEAR_GATEWAY"

export const CREATE_GATEWAY_INIT = "gateway@CREATE_GATEWAY_INIT"
export const CREATE_GATEWAY_SUCCEDED = "gateway@CREATE_GATEWAY_SUCCEDED"
export const CREATE_GATEWAY_FAILED = "gateway@CREATE_GATEWAY_FAILED"

export const FETCH_GATEWAY_INIT = "gateway@FETCH_GATEWAY_INIT"
export const FETCH_GATEWAY_SUCCEDED = "gateway@FETCH_GATEWAY_SUCCEDED"
export const FETCH_GATEWAY_FAILED = "gateway@FETCH_GATEWAY_FAILED"

export const EDIT_GATEWAY_INIT = "gateway@EDIT_GATEWAY_INIT"
export const EDIT_GATEWAY_SUCCEDED = "gateway@EDIT_GATEWAY_SUCCEDED"
export const EDIT_GATEWAY_FAILED = "gateway@EDIT_GATEWAY_FAILED"

export const DELETE_GATEWAY_INIT = "gateway@DELETE_GATEWAY_INIT"
export const DELETE_GATEWAY_SUCCEDED = "gateway@DELETE_GATEWAY_SUCCEDED"
export const DELETE_GATEWAY_FAILED = "gateway@DELETE_GATEWAY_FAILED"
