export const ROUTINE_VACCINATION = 1
export const SEASONAL_VACCINATION = 2
export const VACCINATION_FOR_MOBILITY = 3
export const RABIES_VACCINATION = 4
export const OCCASIONAL_VACCINATION = 5


export const VACCINE_CATEGORY = {
  [ROUTINE_VACCINATION]: "VACCINE.CATEGORY.ROUTINE_VACCINATION",
  [OCCASIONAL_VACCINATION]: "VACCINE.CATEGORY.OCCASIONAL_VACCINATION",
  [SEASONAL_VACCINATION]: "VACCINE.CATEGORY.SEASONAL_VACCINATION",
  [VACCINATION_FOR_MOBILITY]: "VACCINE.CATEGORY.VACCINATION_FOR_MOBILITY",
  [RABIES_VACCINATION]: "VACCINE.CATEGORY.RABIES_VACCINATION",
}
const  formaterListe=(list)=>{
  const result = []
  result[0]=list[0]
  result[1]=list[4]
  result[2]=list[1]
  // result[3]=list[2]
  result[4]=list[3]
return result
}
export const vaccinationCatgoryListUIHelper = (intl) =>{
  const  list = Object.keys(VACCINE_CATEGORY).map((key) => {
    return {
      value: parseInt(key),
      label: intl.formatMessage({ id: VACCINE_CATEGORY[key] }),
    }
  })
  return formaterListe(list)
}
 
