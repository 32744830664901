
export const CHILDRENLIST= "/api/citizen/children_list";
export const CREATE_CHILD = "api/citizen/create_children";
export const CREATE_STRANGER_CHILD = "api/citizen/create_stranger_children";


export const FILTERCHILDREN= "/api/citizen/children_list";
export const CHILD= "/api/citizen/:param/update_children";
export const EDITCHILD= "/api/citizen/:param/update_children";
export const DELETECHILD= "/api/citizen/:param/medical_folder/close";
export const VACCINATION= "/api/vaccination/:param/vaccines_list?status=2";

export const FETCH_GROWTH_LIST = "/api/medical_folder/:param/growth_metrics/list?birthDate=:b_d"

export const FETCH_HEAD_CIRCUMFERENCE_LIST = "/api/medical_folder/:param/head_circumference_metrics/list?birthDate=:b_d"

export const COURBE_GROWTH = "/api/medical_folder/:param/growth_metrics/list?birthDate=:b_d"

export const ADD_SECOND_PARENT = "api/citizen/:param/add_parent"

export const DOWNLOAD_CERTIFICATE = "api/vaccination/:param/generate/certificate/:lang?category=:cat"

export const DOWNLOAD_CARNET = "api/vaccination/:param/generate/certificate/list/:lang"


export const ALLERGY_LIST = "api/medical_folder/allergies/:param/details"

export const FETCH_MAPI_LIST = "api/medical_folder/:param/mapi/list"

export const CHECK_PARENT = "api/citizen/:param/check/add_parent"

export const LINK_CHILD = "api/citizen/registred/create_children";
export const LINK_STRANGER_CHILD = "api/citizen/registered/stranger/create_children";


export const CHANGE_PHONE_PROFILE_OTP = "api/citizen/update_children_phone/send_otp";
export const VERIF_PHONE_PROFILE_UPDATE = "api/citizen/:param/update_children_phone/verify_otp";
