import _ from "lodash"


export const isPermittedPermission = (can, permissions=[]) => {

  if (_.isEmpty(can)){
    return true
  }

  if (!_.isEmpty(can) && !_.isArray(can) && permissions?.includes(can)){
    return true
  }

  if (!_.isEmpty(can) && _.isArray(can) && can.every((permission) => permissions?.includes(permission))){
    return true
  }

  return false
}


export const isPermittedModule = (can, permissions=[]) => {

  if (!_.isEmpty(can) && !_.isArray(can) && permissions?.includes(can)){
    return true
  }

  if (!_.isEmpty(can) && _.isArray(can) && can.every((permission) => permissions?.includes(permission))){
    return true
  }

  return false
}

export const isChild = (age) => {
  if (age?.years < 18)
    return true;
  else 
    return false;

}