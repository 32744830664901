import { ACTIONS } from './../constants'
import { PURGE } from "redux-persist"


const initialState = {
  isLoading: false,
  isFetching: false,
  success: false,
  vacination_categories: [],
  error: null
}


export default (state = initialState, action) => {

  const { payload, type } = action

  switch (type) {

    case ACTIONS.CLEAR_ERROR: {
      return { ...state, isLoading: false, isFetching: false, success: false, error: null }
    }

    case PURGE: {
      return { ...state, isLoading: false, isFetching: false, success: false, error: null }
    }

    case ACTIONS.FETCH_VACCINATION_CATEGORIES_INIT: {
      return { ...state, isFetching: true, success: false, error: null }
    }
    case ACTIONS.FETCH_VACCINATION_CATEGORIES_SUCCEDED: {
      return { ...state, isFetching: false, vacination_categories: payload, error: null }
    }
    case ACTIONS.FETCH_VACCINATION_CATEGORIES_FAILED: {
      return { ...state, isFetching: false, success: false, error: payload }
    }

    default: {
      return state
    }
  }
}
