// organization
export const FETCH_ORGANIZATIONS_INIT = "organization@FETCH_ORGANIZATIONS_INIT"
export const FETCH_ORGANIZATIONS_SUCCEDED = "organization@FETCH_ORGANIZATIONS_SUCCEDED"
export const FETCH_ORGANIZATIONS_FAILED = "organization@FETCH_ORGANIZATIONS_FAILED"

export const CLEAR_ORGANIZATION = "organization@CLEAR_ORGANIZATION"

export const CREATE_ORGANIZATION_INIT = "organization@CREATE_ORGANIZATION_INIT"
export const CREATE_ORGANIZATION_SUCCEDED = "organization@CREATE_ORGANIZATION_SUCCEDED"
export const CREATE_ORGANIZATION_FAILED = "organization@CREATE_ORGANIZATION_FAILED"

export const FETCH_ORGANIZATION_INIT = "organization@FETCH_ORGANIZATION_INIT"
export const FETCH_ORGANIZATION_SUCCEDED = "organization@FETCH_ORGANIZATION_SUCCEDED"
export const FETCH_ORGANIZATION_FAILED = "organization@FETCH_ORGANIZATION_FAILED"

export const EDIT_ORGANIZATION_INIT = "organization@EDIT_ORGANIZATION_INIT"
export const EDIT_ORGANIZATION_SUCCEDED = "organization@EDIT_ORGANIZATION_SUCCEDED"
export const EDIT_ORGANIZATION_FAILED = "organization@EDIT_ORGANIZATION_FAILED"

export const DELETE_ORGANIZATION_INIT = "organization@DELETE_ORGANIZATION_INIT"
export const DELETE_ORGANIZATION_SUCCEDED = "organization@DELETE_ORGANIZATION_SUCCEDED"
export const DELETE_ORGANIZATION_FAILED = "organization@DELETE_ORGANIZATION_FAILED"

export const EDIT_ORGANIZATION_GATEWAYS_INIT ="organization@EDIT_ORGANIZATION_GATEWAYS_INIT"
export const EDIT_ORGANIZATION_GATEWAYS_SUCCEDED = "organization@EDIT_ORGANIZATION_GATEWAYS_SUCCEDED"
export const EDIT_ORGANIZATION_GATEWAYS_FAILED ="organization@EDIT_ORGANIZATION_GATEWYS_FAILED"

export const FETCH_GATEWAYS_INIT ="organization@FETCH_GATEWAYS_INIT"
export const FETCH_GATEWAYS_SUCCEDED = "organization@FETCH_GATEWAYS_SUCCEDED"
export const FETCH_GATEWAYS_FAILED ="organization@FETCH_GATEWYS_FAILED"