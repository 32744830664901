import React from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'

import { AuthenticationError, PermissionDeniedError, isChild, isPermittedPermission } from './../../helpers'
import { ContentRoute } from '../router'

const ProtectedRoute = ({ isAuthenticated = false, anonymous = true, can,ageRestricted=false, isSuperuser, permissions, ...props }) => {
  
  const {
    age,
    forbiddenError
  } = useSelector(
    (state) => ({
      age: state.common.auth.currentUser?.profile.age,
      forbiddenError: state.connectivity.forbiddenError, 

      
    }),
    shallowEqual
  );
  if (!isAuthenticated && !anonymous) {
    throw new AuthenticationError()
  }
  

  // if (!isSuperuser && !isPermittedPermission(can, permissions)) {
  //   throw new PermissionDeniedError()
  // }
  if ((isChild(age) && ageRestricted) || forbiddenError){
    throw new PermissionDeniedError()
  }

  return (<ContentRoute {...props} />)
}

const mapStateToProps = (state) => state.common.auth

export default connect(mapStateToProps)(ProtectedRoute)
