import { CREATE, UPDATE, VIEW, DELETE/*, UNDELETE,VIEW_UPDATE, ACTIVATE, DEACTIVATE,*/ } from "./Permissions"


export const USER = {
  module: "user",
  permissions: {
    [VIEW]: "view_user",
    [CREATE]: "add_user",
    [UPDATE]: "change_user",
    //[ACTIVATE]: "activate_user",
    //[DEACTIVATE]: "delete_user",
    [DELETE]: "soft_delete_user",
    // [UNDELETE]: "undelete_user",
  },
}

export const USER_GROUP = {
  module: "role",
  permissions: {
    [VIEW]: "view_role",
    [CREATE]: "add_role",
    [UPDATE]: "change_role",
    [DELETE]: "delete_role"
  }
}

export const ORGANIZATION = {
  module: "organization",
  permissions: {
    [VIEW]: "view_organization",
    [CREATE]: "add_organization",
    [UPDATE]: "change_organization",
    [DELETE]: "delete_organization"
  }
}

export const GATEWAY = {
  module: "gateway",
  permissions: {
    [VIEW]: "view_gateway",
    [CREATE]: "add_gateway",
    [UPDATE]: "change_gateway",
    [DELETE]: "delete_gateway"
  }
}
export const MY_CARNET = {
  module: "myCarnet",
  permissions: {
    [VIEW]: "view_myCarnet",
    [CREATE]: "add_myCarnet",
    [UPDATE]: "change_myCarnet",
    [DELETE]: "delete_myCarnet"
  }
}
