/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux"

// Reducers
import profileReducer from "./containers/profile/store/reducers"

import gatewayReducer from "./containers/gateway/store/reducers"
import organizationReducer from "./containers/organization/store/reducers"
import childrenReducer from "./containers/childrenSpace/store/reducers"
import myCarnetReducer from "./containers/myCarnet/store/reducers"
import closureReducer from "./containers/closure/store/reducers"

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default combineReducers({
  childrenSpace: childrenReducer,
  profile: profileReducer,
  gateway: gatewayReducer,
  organization: organizationReducer,
  myCarnet: myCarnetReducer,
  closure: closureReducer
})

