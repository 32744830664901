import { HTTP_METHODS } from "src/constants";
import { makeCall } from "src/helpers";
import { isRLTLang } from "src/i18n";
import { store } from "src/configureStore";
import {isEmpty} from "lodash";
const FETCH_ENDPOINT = "/api/country_list";
const formatResponse = (items,param) =>
  items.map((item) => ({
    label:isRLTLang() ? item.labelCountryAr : item.labelCountryFr,
    value: item.idCountry,
    ...item,
  })).filter(item => item.idCountry !== 'CE')
export const CountriesUIHelper = (callback) => {
  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_ENDPOINT, { _pagination: false })
      .then((resp) => resolve(callback(formatResponse(resp.data))))
      .catch(() => reject(callback([])))
  );

};
