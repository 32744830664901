import { lazy } from "react"

const AdminLayout = lazy(() =>  import("./modules/admin/layout/Layout"))
const AuthLayout = lazy(() =>  import("./modules/authentication/layout/Layout"))
const Logout = lazy(() =>  import("./modules/authentication/containers/Logout"))
// const LandingLayout = lazy(() =>  import("./modules/landing/layout/Layout"))
const InscriptionCinPage = lazy( ()=> import("./modules/landing/layout/component/InscriptionPage"))
const InscriptionMethod = lazy( ()=> import("./modules/landing/layout/component/IscriptionMethod"))
const InscriptionWithoutCin=lazy( ()=> import("./modules/landing/layout/component/InscriptionWithoutCin"))
const InscriptionForeign=lazy( ()=> import("./modules/landing/layout/component/InscriptionForeign"))

export default {
  admin: {
    path: "/admin",
    component: AdminLayout
  },
  auth: {
    path: "/auth",
    component: AuthLayout
  },
  logout: {
    path: "/logout",
    component: Logout
  },
  inscription : {
    path: "/inscription",
    component: InscriptionMethod
},  
inscriptionCin : {
  path: "/inscriptionCin",
  component: InscriptionCinPage
}, 
inscriptionWithoutCin : {
  path:"/iscriptionWithoutCin",
  component: InscriptionWithoutCin
},
inscriptionForeign : {
  path:"/iscriptionForeign",
  component: InscriptionForeign
}
  // landing: {
  //   path: "/",
  //   component: LandingLayout
  // },
  
}
