import { ACTIONS } from "../constants";

const initialState = {
  isClosing: false,
  //   error: null,
  //   success: false,
  close_error: false,
  close_success: false,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.FETCH_CLOSURE_INIT: {
      return {
        ...state,
        isClosing: true,
        close_error: null,
        close_success: false,
      };
    }
    case ACTIONS.FETCH_CLOSURE_SUCCEDED: {
      console.log({ payload });
      return {
        ...state,
        isClosing: false,
        close_error: null,
        // success: true,
        close_success: true,
      };
    }
    case ACTIONS.FETCH_CLOSURE_FAILED: {
      return { ...state, isClosing: true, close_error: payload };
    }
    case ACTIONS.CLEAR_CLOSE: {
      return {
        ...state,
        close_error: false,
        close_success: false,
      };
    }

    default:
      return state;
  }
};
