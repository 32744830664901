

// children list
export const FETCH_CHILDRENLIST_INIT = "child@FETCH_CHILDRENLIST_INIT" 
export  const FETCH_CHILDRENLIST_SUCCEDED = "child@FETCH_CHILDRENLIST_SUCCEDED"
export const FETCH_CHILDRENLIST_FAILED = "child@FETCH_CHILDRENLIST_FAILED"

// child create
export const CREATE_CHILD_INIT = "child@CREATE_CHILD_INIT"
export const CREATE_CHILD_SUCCEDED = "child@CREATE_CHILD_SUCCEDED"
export const CREATE_CHILD_FAILED = "child@CREATE_CHILD_FAILED"


// stanger child create
export const CREATE_STRANGER_CHILD_INIT = "child@CREATE_STRANGER_CHILD_INIT"
export const CREATE_STRANGER_CHILD_SUCCEDED = "child@CREATE_STRANGER_CHILD_SUCCEDED"
export const CREATE_STRANGER_CHILD_FAILED = "child@CREATE_STRANGER_CHILD_FAILED"

export const FETCH_CHILD_INIT = "child@FETCH_CHILD_INIT" 
export  const FETCH_CHILD_SUCCEDED = "child@FETCH_CHILD_SUCCEDED"
export const FETCH_CHILD_FAILED = "child@FETCH_CHILD_FAILED"

export const EDIT_CHILD_INIT = "child@EDIT_CHILD_INIT" 
export  const EDIT_CHILD_SUCCEDED = "child@EDIT_CHILD_SUCCEDED"
export const EDIT_CHILD_FAILED = "child@EDIT_CHILD_FAILED"

export const DELETE_CHILD_INIT = "child@DELETE_CHILD_INIT" 
export  const DELETE_CHILD_SUCCEDED = "child@DELETE_CHILD_SUCCEDED"
export const DELETE_CHILD_FAILED = "child@DELETE_CHILD_FAILED"

// clear store 

export const CLEAR_CHILDREN = "child@CLEAR_CHILDREN"

export const CLEAR_CHILD = "child@CLEAR_CHILD"


export const FETCH_VACCINATION_LIST_INIT="vacination@FETCH_VACCINATION_LIST_INIT"
export const FETCH_VACCINATION_LIST_SUCCEDED="vacination@FETCH_VACCINATION_LIST_SUCCEDED"
export const FETCH_VACCINATION_LIST_FAILED="vacination@FETCH_VACCINATION_LIST_FAILED"

export const FETCH_VACCINATION_LIST_BY_CATEORY_INIT="vacination@FETCH_VACCINATION_LIST_BY_CATEORY_INIT"
export const FETCH_VACCINATION_LIST_BY_CATEORY_SUCCEDED="vacination@FETCH_VACCINATION_LIST_BY_CATEORY_SUCCEDED"
export const FETCH_VACCINATION_LIST_BY_CATEORY_FAILED="vacination@FETCH_VACCINATION_LIST_BY_CATEORY_FAILED"


export const FETCH_GROWTH_LIST_INIT = "vaccination@FETCH_GROWTH_LIST_INIT"
export const FETCH_GROWTH_LIST_SUCCEDED = "vaccination@FETCH_GROWTH_LIST_SUCCEDED"
export const FETCH_GROWTH_LIST_FAILED = "vaccination@FETCH_GROWTH_LIST_FAILED"


export const FETCH_HEAD_CIRCUMFERENCE_LIST_INIT = "headCircumference@FETCH_HEAD_CIRCUMFERENCE_LIST_INIT"
export const FETCH_HEAD_CIRCUMFERENCE_LIST_SUCCEDED = "headCircumference@FETCH_HEAD_CIRCUMFERENCE_LIST_SUCCEDED"
export const FETCH_HEAD_CIRCUMFERENCE_LIST_FAILED = "headCircumference@FETCH_HEAD_CIRCUMFERENCE_LIST_FAILED"

export const COURBE_GROWTH_INIT = "headCircumference@COURBE_GROWTH_INIT"
export const COURBE_GROWTH_SUCCEDED = "headCircumference@COURBE_GROWTH_SUCCEDED" 
export const COURBE_GROWTH_FAILED = "headCircumference@COURBE_GROWTH_FAILED"

export const  ADD_SECOND_PARENT_INIT = "parent@ADD_SECOND_PARENT_INIT"
export const ADD_SECOND_PARENT_SUCCEDED = "parent@ADD_SECOND_PARENT_SUCCEDED"
export const ADD_SECOND_PARENT_FAILED = "parent@ADD_SECOND_PARENT_FAILED"

export const FILTER_COURBE = "courbe@FILTER_COURBE"

export const  DOWNLOAD_CERTIFICATE_INIT = "certif@DOWNLOAD_CERTIFICATE_INIT"
export const DOWNLOAD_CERTIFICATE_SUCCEDED = "certif@DOWNLOAD_CERTIFICATE_SUCCEDED"
export const DOWNLOAD_CERTIFICATE_FAILED = "certif@DOWNLOAD_CERTIFICATE_FAILED"


export const ALLERGY_LIST_INIT = "headCircumference@ALLERGY_LIST_INIT"
export const ALLERGY_LIST_SUCCEDED = "headCircumference@ALLERGY_LIST_SUCCEDED" 
export const ALLERGY_LIST_FAILED = "headCircumference@ALLERGY_LIST_FAILED"

export const FETCH_MAPI_LIST_INIT = "mapi@FETCH_MAPI_LIST_INIT"
export const FETCH_MAPI_LIST_SUCCEDED = "mapi@FETCH_MAPI_LIST_SUCCEDED"
export const FETCH_MAPI_LIST_FAILED = "mapi@FETCH_MAPI_LIST_FAILED" 


export const CHECK_PARENT_INIT = "headCircumference@CHECK_PARENT_INIT"
export const CHECK_PARENT_SUCCEDED = "headCircumference@CHECK_PARENT_SUCCEDED" 
export const CHECK_PARENT_FAILED = "headCircumference@CHECK_PARENT_FAILED"
export const COURBE_YEAR_MONTH = "courbe@COURBE_YEAR_MONTH"


export const LINK_CHILD_INIT = "child@LINK_CHILD_INIT"
export const LINK_CHILD_SUCCEDED = "child@LINK_CHILD_SUCCEDED"
export const LINK_CHILD_FAILED = "child@LINK_CHILD_FAILED"


export const CHANGE_PHONE_PROFILE_OTP_INIT = "child@CHANGE_PHONE_PROFILE_OTP_INIT"
export const CHANGE_PHONE_PROFILE_OTP_SUCCEDED = "child@CHANGE_PHONE_PROFILE_OTP_SUCCEDED"
export const CHANGE_PHONE_PROFILE_OTP_FAILED = "child@CHANGE_PHONE_PROFILE_OTP_FAILED"



export const VERIF_PHONE_PROFILE_UPDATE_INIT = "child@VERIF_PHONE_PROFILE_UPDATE_INIT"
export const VERIF_PHONE_PROFILE_UPDATE_SUCCEDED = "child@VERIF_PHONE_PROFILE_UPDATE_SUCCEDED"
export const VERIF_PHONE_PROFILE_UPDATE_FAILED = "child@VERIF_PHONE_PROFILE_UPDATE_FAILED"

export const LINK_STRANGER_CHILD_INIT = "child@LINK_STRANGER_CHILD_INIT"
export const LINK_STRANGER_CHILD_SUCCEDED = "child@LINK_STRANGER_CHILD_SUCCEDED"
export const LINK_STRANGER_CHILD_FAILED = "child@LINK_CHILD_FAILED"
export const CLEAR_CHILDREN_1 = "child@CLEAR_CHILDREN_1"


